import './style.scss';

import { APP_URL } from '../../../utils/utility';
import { Button } from '@bcmi-labs/react-components';
import { Helmet } from 'react-helmet';
import Layout from '../../../components/layout';
import React from 'react';
import SEO from '../../../components/seo';
import confirmationPageImg from '../../../assets/NewsletterSubscription.png';
import { useLocation } from '@reach/router';

export default () => {
  const title = 'Subscription Confirmed';

  const search = useLocation().search;
  const isEmail = Boolean(new URLSearchParams(search).get('email'));
  if (!isEmail && typeof window !== 'undefined') {
    window.location.href = APP_URL;
  }

  return (
    <Layout currentLocale="en">
      <Helmet
        bodyAttributes={{
          class: 'newsletter-page-body',
        }}
      />
      <SEO title={title} />
      <main className="newsletter-page page">
        <section>
          <div className="page-content">
            <img className="newsletter-page__image" src={confirmationPageImg} alt="Confirmation page" />
            <div className="newsletter-page__title">Thank you.</div>
            <div className="newsletter-page__subtitle">
              We will be happy to keep you updated with Arduino news and creative projects!
            </div>
            <Button
              variant="primary"
              onPress={() => (typeof window !== 'undefined' ? window.location.replace(APP_URL) : null)}
            >
              GO TO HOME PAGE
            </Button>
          </div>
        </section>
      </main>
    </Layout>
  );
};
